.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  }

  .dashboard-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin: 0;
  }

  .dashboard-nav {
    display: flex;
    gap: 1rem;
  }

  .nav-button {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    color: #666;
    font-size: 0.875rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }

  .nav-button:hover {
    background-color: #f0f0f0;
  }

  .nav-button.logout {
    color: #dc3545;
  }

  .section-title {
    font-size: 1.125rem;
    color: #333;
    margin: 0 0 1rem 0;
  }

  .event-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
  }

  .event-card {
    background: white;
    border-radius: 8px;
    padding: 1.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .event-title {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 1rem 0;
  }

  .event-details {
    font-size: 0.875rem;
    color: #666;
  }

  .event-details p {
    margin: 0.5rem 0;
  }

  .event-details span {
    font-weight: 500;
    color: #444;
  }

  .status {
    color: #28a745;
    font-size: 0.875rem;
    margin: 0.75rem 0;
  }

  .check-users-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.2s;
  }

  .check-users-btn:hover {
    background: #0056b3;
  }

  @media (max-width: 768px) {
    .dashboard-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
    }

    .dashboard-nav {
      width: 100%;
      justify-content: space-around;
    }

    .event-grid {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 480px) {
    .dashboard-container {
      padding: 0.5rem;

    }
    .nav-button {
      padding: 0.4rem 0.75rem;
      font-size: 0.8125rem;
    }

    .event-card {
      padding: 1rem;
    }
  }