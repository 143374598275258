/* Center the page content */
.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background: linear-gradient(135deg, #6a11cb, #2575fc); Modern gradient */
    color: black;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  /* Content box */
  .coming-soon-content {
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 400px;
  }
  
  /* Title */
  .coming-soon-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  /* Message */
  .coming-soon-message {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  