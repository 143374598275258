/* Base Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .review-form {
    max-width: 100%; /* Make it fully responsive */
    margin:  auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Ensure inputs are responsive */
  }
  
  .form-group textarea {
    min-height: 100px;
  }
  
  .rating-stars {
    display: flex;
    gap: 5px;
  }
  
  .rating-stars span {
    font-size: 24px;
    cursor: pointer;
  }
  
  .satisfaction-level {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 10px;
  }
  
  .satisfaction-level .level {
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    /* background-color: #f8f8f8; */
    border: 1px solid #ddd;
    text-align: center;
    flex: 1 1 calc(50% - 10px); /* Adapt to mobile */
    box-sizing: border-box;
  }
  
  .radio-group {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 10px;
    align-items: center;
  }

  .recommend-que{
    font-weight: bold;
    
  }
  .recommend{
    margin-bottom: 25px;
  }
  .level-1 {
    background-color: #ffcccc; 
    color: #b00020;
    border-color: #b00020;
  }
  
  .level-2 {
    background-color: #ffd580; 
    color: #e65100;
    border-color: #e65100;
  }
  
  .level-3 {
    background-color: #ffecb3; /* Satisfied */
    color: #827717;
    border-color: #827717;
  }
  
  .level-4 {
    background-color: #dcedc8; /* Happy */
    color: #33691e;
    border-color: #33691e;
  }
  
  .level-5 {
    background-color: #b2dfdb; /* Very Happy */
    color: #004d40;
    border-color: #004d40;
  }
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

  .form-logo {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-logo img {
    max-width: 150px; /* Adjust size as needed */
    height: auto;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .review-form {
      padding: 15px;
    }
  
    .form-group label {
      font-size: 14px;
    }
  
    .satisfaction-level .level {
      flex: 1 1 100%; /* Full width on small screens */
    }
  
    .submit-button {
      font-size: 14px;
    }
  }
  