/* General styles */
.container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* Add a light background color */
  }
  
  .heading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logo {
    display: block;
    margin: 0 auto;
    max-width: 120px; /* Control the size of the logo */
    margin-bottom: 20px; /* Space between logo and content */
  }
  
  .spinner {
    margin: 20px auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-top: 20px;
  }
  
  .no-users {
    text-align: center;
    margin-top: 20px;
  }
  
  .refresh-button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    transition: background-color 0.3s;
  }
  
  .refresh-button:hover {
    background-color: #2980b9;
  }
  
  .refresh-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5);
  }
  
  /* Table styles */
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .user-table th,
  .user-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  
  .user-table td {
    background-color: #fff;
  }
  
  .mark-button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .mark-button:hover {
    background-color: #45a049;
  }
  
  .mark-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(72, 180, 97, 0.5);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .user-table th,
    .user-table td {
      padding: 10px;
      font-size: 14px;
    }
  
    .mark-button {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .user-table th,
    .user-table td {
      padding: 8px;
      font-size: 12px;
    }
  
    .mark-button {
      padding: 5px 10px;
      font-size: 10px;
    }
  
    .heading {
      font-size: 20px;
    }
  }
  
  .total-users {
    text-align: center; /* Centers the text horizontally */
    margin-bottom: 20px; /* Optional: adds space below the text */
    font-size: 18px; /* Optional: adjusts the font size */
    font-weight: bold; /* Optional: makes the font bold */
  }