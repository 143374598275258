/* ProductCard.css */
.product-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 280px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .product-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .product-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 12px;
  }
  
  .product-price {
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin-bottom: 16px;
  }
  
  .buy-now-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buy-now-button:hover {
    background-color: gray;
  }
  