/* Center the form vertically and horizontally */
.otp-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f4ff;
    padding: 20px;
    box-sizing: border-box;
}

.otp-form {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.otp-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.otp-input {
    margin-bottom: 20px;
}

.btn-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .otp-form {
        padding: 15px;
    }

    .otp-form h2 {
        font-size: 20px;
    }

    .btn-container {
        flex-direction: column;
    }

    .btn-container .ms-2 {
        margin-left: 0 !important;
        margin-top: 10px;
    }
}
