body {
    margin: 0;
    font-family: 'Poppins', Arial, sans-serif;
    /* background-color: #fff8e1; Light yellow background */
    /* color: #333; */
}

/* Main Container for the Page */
.product-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff8e1;
}

/* Header Section */
.page-header {
    background-color: #ffcc00; /* Bright yellow header */
    color: white;
    text-align: center;
    padding: 30px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.page-header h1 {
    margin: 0;
    font-size: 3rem;
    font-weight: 700;
}

.page-header p {
    margin: 10px 0 0;
    font-size: 1.2rem;
}

/* Main Content Section */
.page-main {
    flex: 1;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff8e1; /* Ensures background is consistent */
}

/* Footer Section */
.page-footer {
    background-color: #ffcc00; /* Matching footer color */
    color: white;
    text-align: center;
    padding: 15px 0;
    font-size: 0.9rem;
}

.page-footer p {
    margin: 0;
    color: white;
}

/* Product Card Styles (assumed to be in ProductList) */
.product-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 15px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.product-card:hover {
    transform: translateY(-5px); /* Slight hover effect */
}

.product-card h2 {
    font-size: 1.5rem;
    color: #333;
}

.product-card p {
    color: #777;
    font-size: 1rem;
    margin: 10px 0;
}

.product-card .price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #ff9800; /* Orange price */
}

.product-card .buy-button {
    background-color: #ffcc00; /* Yellow button */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.product-card .buy-button:hover {
    background-color: #f57f17; /* Darker yellow-orange on hover */
}

.product-card .buy-button:focus {
    outline: none;
}
