/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7fc;
  color: #333;
}

.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
  background: #f4f7fc;
}

.unauthorized-content {
  text-align: center;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.unauthorized-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff4d4d;
  margin-bottom: 20px;
  text-align: center;
}

.unauthorized-message {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .unauthorized-title {
    font-size: 2rem;
  }

  .unauthorized-message {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .unauthorized-title {
    font-size: 1.75rem;
  }

  .unauthorized-message {
    font-size: 0.95rem;
  }
}
