/* Common container style */
.verification-success-container,
.verification-failed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Logo */
.logo {
    width: 250px;
    margin-bottom: 20px;
}

/* Success Icon */
.success-icon {
    font-size: 60px;
    color: #4caf50;
    margin-bottom: 20px;
}

/* Error Icon */
.error-icon {
    font-size: 60px;
    color: #f44336;
    margin-bottom: 20px;
}

/* Heading Style */
h2 {
    margin-bottom: 10px;
    font-weight: bold;
}

/* Success Heading Text Color */
.verification-success-container h2 {
    color: #4caf50;
}

/* Failure Heading Text Color */
.verification-failed-container h2 {
    color: #f44336;
}

/* Description Paragraph */
p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
}

/* Success Background Color */
.verification-success-container {
    background-color: #e0f7df;
}

/* Failure Background Color */
.verification-failed-container {
    background-color: #fbe9e7;
}

/* Logout Button Styling */
.logout-button {
    padding: 12px 24px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.logout-button:hover {
    background-color: #43a047;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.logout-button:active {
    background-color: #388e3c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
