/* Basic styling for the page */
.event-list-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
.admin-login-container{
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif; */
}

  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .events-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
  }
  
  .event-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .event-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .event-card h3 {
    font-size: 20px;
    color: #333;
  }
  
  .event-card p {
    font-size: 14px;
    color: #555;
  }
  
  .event-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .event-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .events-wrapper {
      grid-template-columns: 1fr;
    }
  }
  