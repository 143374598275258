/* Container for the entire question */
.question-container {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Question title */
  .question-container h3 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  /* Container for all options */
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Label for each option */
  .option-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
    cursor: pointer;
  }
  
  /* Input styles (radio/checkbox) */
  .option-label input[type="radio"],
  .option-label input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2);
    cursor: pointer;
  }
  
  /* Highlight selected option */
  .option-label input[type="radio"]:checked + span,
  .option-label input[type="checkbox"]:checked + span {
    font-weight: bold;
    color: #333;
  }
  
  /* Styles for the "Other" input field */
  .other-input {
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  .other-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .question-container {
      padding: 15px;
    }
  
    .question-container h3 {
      font-size: 16px;
    }
  
    .option-label {
      font-size: 14px;
    }
  
    .other-input {
      font-size: 14px;
    }
  }
  